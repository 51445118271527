import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import NewsSection from '../components/homeNews'
import ColumnOverlayFocus from '../components/columnOverlayFocus'
import ColumnAlternatingColour from '../components/columnAlternatingColour'
import Card from '../components/card'

const Index = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sls-intro-page">
        <Hero
          class="home-110"
          foreimage={post.frontmatter.image.childImageSharp.gatsbyImageData}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Why SoftLanding?"
          ctaslug2="/contact/"
          ctatext2="Get in touch"
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <ColumnOverlayFocus focus={post.frontmatter.focus} />
        </MDBContainer>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <h2 className="font-alt font-w-700 title-xs-medium title-large letter-spacing-1 text-center mt-4 mt-xl-0 mb-7">
                      {post.frontmatter.spotlighttitle}
                    </h2>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>

              <MDBContainer>
                {post.frontmatter.section.map((sections, index) => (
                  <ColumnAlternatingColour
                    key={index}
                    title={sections.title}
                    subtitle={sections.subtitle}
                    subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : '' }
                    description={sections.description}
                    image={sections.image.childImageSharp.gatsbyImageData}
                    alt={sections.alttext}
                    placement={sections.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                    link={sections.btnlink}
                    colour={ sections.btncolour != 'none' ? sections.btncolour : null }
                  />
                  )
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        ) : (
          ''
        )}

        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms">
            <MDBContainer>
              <h2 className="font-alt font-w-700 text-center letter-spacing-1 mt-4 mt-xl-0 mb-5 title-xs-medium title-large">
                {post.frontmatter.insights.title}
              </h2>
              {post.frontmatter.insights.items && (
                <MDBRow className="pt-4">
                  <>
                    {post.frontmatter.insights.items.map((item, index) => {
                      return (
                        <Card
                          key={index}
                          collg="4"
                          colmd="6"
                          height="12.5rem"
                          title={item.title}
                          subtitle={item.subtitle}
                          description={item.description}
                          image={item.image.childImageSharp.gatsbyImageData}
                          alt={item.alttext}
                          placement={item.placement}
                          link={item.link ? item.link : item.document.publicURL}
                          descriptionClass="text-card-small"
                        />
                      )
                    })}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </MDBAnimation>
        </section>        

        <NewsSection />
      </main>
    </Layout>
  )
}
export default Index

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {   
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        cardtitle
        cardtext
        spotlighttitle
        cardtagtext
        cardtext
        image: foreimage {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, width: 540, quality: 90) 
          }
        }
        alttext
        focus {
          title
          subtitle
          items {
            title
            subtitle
            image {
              childImageSharp {
                gatsbyImageData(width: 90, quality: 90) 
              }
            }
            alttext
            link
          }
        }
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
              childImageSharp {
                gatsbyImageData(width: 960, quality: 90) 
              }
            }
          placement
          alttext
          btnlink
          btncolour
        }
        insights {
          title
          items {
            title
            subtitle
            description
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 90)
              }
            }
            placement
            alttext
            link
            document {
              publicURL
            }
          }
        }        
      }
    }
  }
`